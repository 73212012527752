import Web3 from "web3";
import { erc20Abi } from "../Abi/erc20abi";
import { homebridgeAbi } from "../Abi/homebridgeabi";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config/config";


export const transferbsctoken = async (data = {}) => {
    var web3js = new Web3(window.ethereum);
    const accounts = data.useraccount;
    let accountlower = accounts.toLowerCase();
    const depositamount = data.deposits;
    const selecttoken = data.tokenselect;
    const ip = data.ipaddr;
    let id = data.id;
    let refaddr = data.refaddr;
    let toamountchange = data.toamountchange;
    const databasefromamt =  web3js.utils.toWei(depositamount, "ether")
    const btomfromcontract = new web3js.eth.Contract(erc20Abi,config.BSC_TOKEN_ADDRESS);
    const bscbridgecontract = new web3js.eth.Contract(homebridgeAbi,config.BSC_BRIDGE_ADDRESS);
    const transfertoken = web3js.utils.toWei(depositamount, "ether");
    if (selecttoken == "USDT (BEP20)") {
      await btomfromcontract.methods.approve(config.BSC_BRIDGE_ADDRESS, transfertoken)
        .send({ from: accounts });
      Swal.fire({
        icon: "warning",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction is Processing",
        html: "Do Not Refresh or Reload the Page",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(transfertoken)
        .send({
          from: accounts
        }).on('error', function(error, receipt) { 
          if(error.code == 4001) {
            window.location.reload()
          }
      });
      const txhash = transferfn.transactionHash;
      var networkname = "BSC";
      var transferobjects = {
        accounts,
        networkname,
        txhash,
        refaddr,
        toamountchange
      };
      console.log("transferobjects-=----------",transferobjects);
      const paymentstatus = transferfn.status;
      if (paymentstatus == true) {
        const dcoinget = await axios.post(config.API_URL, transferobjects);
        await axios({
          method: "POST",
          url: `${config.APP_API_URL}referral_register?id=${id}`,
          data: {
              userAddress: accountlower,
              ip_address: ip,
              first_purchase_amt: databasefromamt,
              currency_type: "USDT",
              toamountchange : toamountchange
          },
        })
          .then(async (userData) => {
            console.log("userData======", userData);
          })
          .catch((err) => console.log(err));
       
        Swal.fire({
          icon: "success",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction confirmed",
          html:
            "Your transaction hash is:<br/><br/>" +
            `<a href=${
              "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
            }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
                      <br/><br/>Refer & Earn 5% Commission`,
        }).then(function () {
          window.location = "/leaderboard";
        });
      } else {
        Swal.fire({
          icon: "error",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction Not confirmed",
          html: "Your transaction hash is Not confirmed",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction is Processing",
        html: "Do Not Refresh or Reload the Page",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(0)
        .send({
          from: accounts,
          value: web3js.utils.toWei(depositamount, "ether"),
        }).on('error', function(error, receipt) { 
          if(error.code == 4001) {
            window.location.reload()
          }
      });
      const txhash = transferfn.transactionHash;
      var networkname = "BSC";
      var transferobjects = {
        accounts,
        networkname,
        txhash,
        refaddr,
        toamountchange
      };
      const paymentstatus = transferfn.status;
      if (paymentstatus == true) {
        const dcoinget = await axios.post(config.API_URL, transferobjects);
        await axios({
          method: "POST",
          url: `${config.APP_API_URL}referral_register?id=${id}`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          data: {
            userAddress: accountlower,
            ip_address: ip,
            first_purchase_amt: databasefromamt,
            currency_type: "BSC",
            toamountchange : toamountchange
          },
        })
          .then(async (userData) => {
            console.log("userData======", userData);
          })
          .catch((err) => console.log(err));
        
        Swal.fire({
          icon: "success",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction confirmed",
          html:
            "Your transaction hash is:<br/><br/>" +
            `<a href=${
              "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
            }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
                      <br/><br/>Refer & Earn 5% Commission`,
        }).then(function () {
          window.location = "/leaderboard";
        });
      } else {
        Swal.fire({
          icon: "error",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction Not confirmed",
          html: "Your transaction hash is Not confirmed",
        });
      }
    }
}


export const transferethtoken = async (data = {}) => {
    console.log("data--",data);
    var web3js = new Web3(window.ethereum);
    const accounts = data.useraccount;
    let accountlower = accounts.toLowerCase();
    const depositamount = data.deposits;
    const selecttoken = data.tokenselect;
    const ip = data.ipaddr;
    let id = data.id;
    let refaddr = data.refaddr;
    let toamountchange = data.toamountchange;
    const databasefromamt =  web3js.utils.toWei(depositamount, "ether")
    const bscbridgecontract = new web3js.eth.Contract(homebridgeAbi,config.ETH_BRIDGE_ADDRESS);
    const ethtokencontract = new web3js.eth.Contract(erc20Abi, config.ETH_TOKEN_ADDRESS);
    const transfertoken = web3js.utils.toWei(depositamount, "Mwei");
    if (selecttoken == "USDT (ERC20)") {
      await ethtokencontract.methods.approve(config.ETH_BRIDGE_ADDRESS, transfertoken).send({ from: accounts });
        Swal.fire({
          icon: "warning",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction is Processing",
          html: "Do Not Refresh or Reload the Page",
          allowOutsideClick: false,
          showConfirmButton: false,
        });
      const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(transfertoken).send({
          from: accounts,
        }).on('error', function(error, receipt) { 
          if(error.code == 4001) {
            window.location.reload()
          }
      });
      const txhash = transferfn.transactionHash;
      var networkname = "ETH";
      var transferobjects = {
        accounts,
        networkname,
        txhash,
        refaddr,
        toamountchange
      };
      const paymentstatus = transferfn.status;
      if (paymentstatus == true) {
        const dcoinget = await axios.post(config.API_URL, transferobjects);
        await axios({
          method: "POST",
          url: `${config.APP_API_URL}referral_register?id=${id}`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          data: {
            userAddress: accountlower,
            ip_address: ip,
            first_purchase_amt: databasefromamt,
            currency_type: "USDT",
            toamountchange : toamountchange
          },
        })
          .then(async (userData) => {
            console.log("userData======", userData);
          })
          .catch((err) => console.log(err));
        
        Swal.fire({
          icon: "success",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction confirmed",
          html:
            "Your transaction hash is:<br/><br/>" +
            `<a href=${
              "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
            }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
                      <br/><br/>Refer & Earn 5% Commission`,
        }).then(function () {
          window.location = "/leaderboard";
        });
      } else {
        Swal.fire({
          icon: "error",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction Not confirmed",
          html: "Your transaction hash is Not confirmed",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction is Processing",
        html: "Do Not Refresh or Reload the Page",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(0).send({
          from: accounts,
          value: web3js.utils.toWei(depositamount, "ether"),
        }).on('error', function(error, receipt) { 
          if(error.code == 4001) {
            window.location.reload()
          }
      });
      const txhash = transferfn.transactionHash;
      var networkname = "ETH";
      var transferobjects = {
        accounts,
        networkname,
        txhash,
        refaddr,
        toamountchange
      };
      const paymentstatus = transferfn.status;
      if (paymentstatus == true) {
        const dcoinget = await axios.post(config.API_URL, transferobjects);
        const payload = {
          userAddress: accountlower,
          ip_address: ip,
          first_purchase_amt: databasefromamt,
          currency_type: "ETH",
          toamountchange : toamountchange
        };
        await axios({
          method: "POST",
          url: `${config.APP_API_URL}referral_register?id=${id}`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          data: payload,
        })
          .then(async (userData) => {
            console.log("userData======", userData);
          })
          .catch((err) => console.log(err));
        
        Swal.fire({
          icon: "success",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction confirmed",
          html:
            "Your transaction hash is:<br/><br/>" +
            `<a href=${
              "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
            }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
                      <br/><br/>Refer & Earn 5% Commission`,
        }).then(function () {
          window.location = "/leaderboard";
        });
      } else {
        Swal.fire({
          icon: "error",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction Not confirmed",
          html: "Your transaction hash is Not confirmed",
        });
      }
    }
}


export const transfermatictoken = async (data = {}) => {
    var web3js = new Web3(window.ethereum);
    const accounts = data.useraccount;
    let accountlower = accounts.toLowerCase();
    const depositamount = data.deposits;
    const selecttoken = data.tokenselect;
    const ip = data.ipaddr;
    let id = data.id;
    let refaddr = data.refaddr;
    let toamountchange = data.toamountchange;
    const databasefromamt =  web3js.utils.toWei(depositamount, "Mwei")
    const bscbridgecontract = new web3js.eth.Contract(homebridgeAbi,config.MATIC_BRIDGE_ADDRESS);
    const matictokencontract = new web3js.eth.Contract(erc20Abi,config.MATIC_TOKEN_ADDRESS);
    const transfertoken = web3js.utils.toWei(depositamount, "Mwei");
    if (selecttoken == "USDT") {
      await matictokencontract.methods.approve(config.MATIC_BRIDGE_ADDRESS, transfertoken).send({ from: accounts });
      Swal.fire({
        icon: "warning",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction is Processing",
        html: "Do Not Refresh or Reload the Page",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(transfertoken)
        .send({
          from: accounts,
        }).on('error', function(error, receipt) { 
          if(error.code == 4001) {
            window.location.reload()
          }
      });
      const txhash = transferfn.transactionHash;
      var networkname = "MATIC";
      var transferobjects = {
        accounts,
        networkname,
        txhash,
        refaddr,
        toamountchange
      };
      const paymentstatus = transferfn.status;
      if (paymentstatus == true) {
        const dcoinget = await axios.post(config.API_URL, transferobjects);
        await axios({
          method: "POST",
          url: `${config.APP_API_URL}referral_register?id=${id}`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          data: {
            userAddress: accountlower,
            ip_address: ip,
            first_purchase_amt: databasefromamt,
            currency_type: "USDT",
            toamountchange : toamountchange
          },
        })
          .then(async (userData) => {
            console.log("userData======", userData);
          })
          .catch((err) => console.log(err));
          Swal.fire({
            icon: "success",
            background: "#041325",
            border: "1px solid #00e5ff21",
            color: "#ffffff",
            title: "Transaction confirmed",
            html:
              "Your transaction hash is:<br/><br/>" +
              `<a href=${
                "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
              }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
                        <br/><br/>Refer & Earn 5% Commission`,
          }).then(function () {
            window.location = "/leaderboard";
          });
      } else {
        Swal.fire({
          icon: "error",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction Not confirmed",
          html: "Your transaction hash is Not confirmed",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction is Processing",
        html: "Do Not Refresh or Reload the Page",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(0)
        .send({
          from: accounts,
          value: web3js.utils.toWei(depositamount, "ether"),
        }).on('error', function(error, receipt) { 
          if(error.code == 4001) {
            window.location.reload()
          }
      });
      const txhash = transferfn.transactionHash;
      var networkname = "MATIC";
      var transferobjects = {
        accounts,
        networkname,
        txhash,
        refaddr,
        toamountchange
      };
      const paymentstatus = transferfn.status;
      if (paymentstatus == true) {
        const dcoinget = await axios.post(config.API_URL, transferobjects);
        await axios({
          method: "POST",
          url: `${config.APP_API_URL}referral_register?id=${id}`,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          data: {
            userAddress: accountlower,
            ip_address: ip,
            first_purchase_amt: databasefromamt,
            currency_type: "MATIC",
            toamountchange : toamountchange
          },
        })
          .then(async (userData) => {
            console.log("userData======", userData);
          })
          .catch((err) => console.log(err));
        
        Swal.fire({
          icon: "success",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction confirmed",
          html:
            "Your transaction hash is:<br/><br/>" +
            `<a href=${
              "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
            }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
                      <br/><br/>Refer & Earn 5% Commission`,
        }).then(function () {
          window.location = "/leaderboard";
        });
      } else {
        Swal.fire({
          icon: "error",
          background: "#041325",
          border: "1px solid #00e5ff21",
          color: "#ffffff",
          title: "Transaction Not confirmed",
          html: "Your transaction hash is Not confirmed",
        });
      }
    }
}
import React, { useState, useEffect } from 'react';
import './App.css';
import Bridge from "./Bridge"
import Transactions from "./Transactions"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Referral from './referral';
import Leaderboard from './leaderboard';
import Errorpage from './Errorpage';
import { ToastContainer, toast } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer/>
      <Router>
        <Routes>
          <Route path="/" element={<Bridge />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path='/referral/:id' element={<Referral/>}/>
          <Route path='/leaderboard' element={<Leaderboard/>} />
          <Route path="/*" element={<Errorpage/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

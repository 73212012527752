import { useState, useEffect } from "react";
import $ from "jquery";
import './Style.css';
import { HiArrowDown } from 'react-icons/hi';

import Web3 from 'web3'
import { erc20Abi } from './Abi/erc20abi'
import bscimage from './images/bscimages.svg.png'
import maticimg from './images/polygon.svg'
import ethimg from './images/Eth.png'
import dchainimg from './images/dchainimages.png'
import axios from 'axios';
import Navigate from "./components/navigate";
import config from './config/config';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

import { transferbsctoken, transferethtoken, transfermatictoken } from './transferbridge/refertransferbsc';
import { transferbscdcxtoken, transferethdcxtoken, transfermaticdcxtoken } from './transferbridge/refertransferbscdcx';


function Referral() {
  const navigate = useNavigate()
  const { id } = useParams();

  const [depositamount, setdepositamount] = useState(0);
  const [dropdownvalue, setdropdownvalue] = useState("");
  const [bridgesoption, setbridgesoption] = useState([
    { value: 1, label: "USDT" },
    { value: 2, label: "ETH" }
  ]);
  const [tobridgesoption, settobridgesoption] = useState([
    { value: 1, label: "DCX" },
    { value: 2, label: "DUSD" }
  ]);
  const [networkrpc, setnetworkrpc] = useState("");
  const [networkchainid, setnetworkchainid] = useState("");
  const [accountaddress, setaccountaddress] = useState("");
  const [chainname, setchainname] = useState("");
  const [bscchain, setbscchain] = useState("BSC");
  const [selecttoken, setselecttoken] = useState("0");
  const [tokenbalance, settokenbalance] = useState("0");
  const [tokensymbol, settokensymbol] = useState("ETH");
  const [networklogo, setnetworklogo] = useState(ethimg);

  const [bscbal, setbscbal] = useState('0');

  const [bridgenetworklogo, setbridgenetworklogo] = useState(dchainimg);
  const [bridgenetworksymbol, setbridgenetworksymbol] = useState('DCX');
  const [network, setnetwork] = useState();
  const [dcxamountvalue, setdcxamountvalue] = useState(0);
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState("")
  const [ReferralUserAddress, setReferralUserAddress] = useState("");
  const [tocontractaddress, settocontractaddress] = useState('');

  const [todropdownvalue, settodropdownvalue] = useState("");

  const [ip, setIP] = useState('');
  const [usdchangeran, setusdchangeran] = useState();

  const getIP = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)

  }

  const torewardset = async (event) => {
    settodropdownvalue(event.target.value)
    const userAddress = sessionStorage.getItem("chainaccounts")
    var web3dchainjs = new Web3(config.dchainrpcurl);
    var web3dchainmainjs = new Web3(config.dchainrpcurl);
    if (event.target.value == "DUSD") {
      const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.DUSD_TOKEN_ADDRESS);
      const dchainbalance = await dchaincontract.methods.balanceOf(userAddress).call();
      const balancesdchain = web3dchainjs.utils.fromWei(dchainbalance, 'ether');
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
      setbridgenetworksymbol("DUSD")
      setusdchangeran(1)
      settocontractaddress("DUSD")
      changefromvalues(depositamount, 1)
    }
    else {
      const dchainbalance = await web3dchainmainjs.eth.getBalance(userAddress);
      const balancesdchain = web3dchainmainjs.utils.fromWei(dchainbalance, "ether");
      const balancesdchainfixed = Number(balancesdchain).toFixed(8)
      setbscbal(balancesdchainfixed)
      setbridgenetworksymbol("DCX")
      settocontractaddress("DCX")
      const res = await axios.get(`${config.APP_API_URL}api/coinmarketcap`);
        const data = res.data.data.DCX.quote.USD.price;
        const usd = data.toFixed(4);
      
  
      changefromvalues(depositamount, usd)
      if (usd > 0) {
        setusdchangeran(usd)
      }else{
        setusdchangeran(0)
      }
     
      
    }
  }


  const rewardset = async (event) => {
    setdropdownvalue(event.target.value)
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const chainid = await window.ethereum.request({ method: "eth_chainId" });
    sessionStorage.setItem('chainname', "metamask")
    sessionStorage.setItem('chainaccounts', accounts[0])
    if (chainid == '0x38') {
      if (event.target.value == "BSC") {
        setdepositamount(0)
        setbscchain("BSC")
        settokensymbol("BSC")
        var web3js = new Web3(config.bsctestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(3);
        var web3dchainjs = new Web3(config.dchainrpcurl);
       
        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        // setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("DCX")

      }
      else {
        setdepositamount(0)
        setbscchain("BSC")
        settokensymbol("USDT")
        var web3js = new Web3(config.bsctestnetrpcurl);
        const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.BSC_TOKEN_ADDRESS);
        const weibalance = await btomfromcontract.methods.balanceOf(accounts[0]).call();
        const balances = web3js.utils.fromWei(weibalance, 'ether');
        const tokenbalance = parseFloat(balances).toFixed(2);
        const symbol = await btomfromcontract.methods.symbol().call()
        var web3dchainjs = new Web3(config.dchainrpcurl);
    

        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        // setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("DCX")
      }
    }
    else if (chainid == '0x89') {
      if (event.target.value == "MATIC") {
        setdepositamount(0)
        setbscchain("MATIC")
        settokensymbol("MATIC")
        var web3js = new Web3(config.matictestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balancesmatic = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balancesmatic).toFixed(3);
        var web3dchainjs = new Web3(config.dchainrpcurl);
      
        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        // setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("DCX")
      }
      else {
        setdepositamount(0)
        var web3js = new Web3(config.matictestnetrpcurl);
        const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.MATIC_TOKEN_ADDRESS);
        const weibalance = await btomfromcontract.methods.balanceOf(accounts[0]).call();
        const balances = web3js.utils.fromWei(weibalance, 'Mwei');
        const tokenbalance = parseFloat(balances).toFixed(2);
        const symbol = await btomfromcontract.methods.symbol().call()
        var web3dchainjs = new Web3(config.dchainrpcurl);
      
        setbscchain("MATIC")
        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        settokensymbol("USDT")
        // setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("DCX")
      }
    }
    else if (chainid == '0x1') {
      if (event.target.value == "ETH") {
        setdepositamount(0)
        var web3js = new Web3(config.web3dchainurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(4);
        var web3dchainjs = new Web3(config.dchainrpcurl);


        setbscchain("ETH")
        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        settokensymbol("ETH")
        // setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("DCX")
      }
      else {
        setdepositamount(0)
        var web3js = new Web3(config.web3dchainurl);
        const btomfromcontract = new web3js.eth.Contract(erc20Abi, config.ETH_TOKEN_ADDRESS);
        const weibalance = await btomfromcontract.methods.balanceOf(accounts[0]).call();
        const balances = web3js.utils.fromWei(weibalance, 'Mwei');
        const tokenbalance = parseFloat(balances).toFixed(2);
        const symbol = await btomfromcontract.methods.symbol().call()

        var web3dchainjs = new Web3(config.dchainrpcurl);
     

        setbscchain("ETH")
        setselecttoken(event.target.value)
        settokenbalance(tokenbalance)
        settokensymbol("USDT")
        // setbscbal(balancesdchainfixed)
        setbridgenetworksymbol("DCX")
      }
    }
    else {
      console.log("wrong network")
    }
  }

  async function bridges() {
    var web3js = new Web3(window.ethereum);
    const chainid = await window.ethereum.request({ method: "eth_chainId" });
    const chainname = sessionStorage.getItem('chainname');
    setchainname(chainname)
    if (chainid == '0x38') {
      $('.switch-tabs-section-eth').css('background', 'transparent');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      settokensymbol('BSC')
      setnetworklogo(bscimage)
      const bridgeOptions = [
        { value: 1, label: "BSC" },
        { value: 2, label: "USDT (BEP20)" }
      ]
      setbridgesoption(bridgeOptions)
    
    }
    else if (chainid == "0x1") {
      $('.switch-tabs-section-eth').css('background', '#1b9ffe');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', 'transparent');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      settokensymbol('ETH')
      setnetworklogo(ethimg)
      const bridgeOptions = [
        { value: 1, label: "ETH" },
        { value: 2, label: "USDT (ERC20)" }
      ]
      setbridgesoption(bridgeOptions)
    }
    else if (chainid == "0x89") {
      $('.switch-tabs-section-eth').css('background', 'transparent');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', 'transparent');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', '#1b9ffe');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      settokensymbol('MATIC')
      setnetworklogo(maticimg)
      const bridgeOptions = [
        { value: 1, label: "MATIC" },
        { value: 2, label: "USDT" }
      ]
      setbridgesoption(bridgeOptions)
    }
    else {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex('0x1') }]
      });
    }
  }

  //bsc
  const metamask = async () => {
    const chainname = sessionStorage.getItem('chainname');
    setchainname(chainname)
    setdcxamountvalue(0)
    setdropdownvalue("")
    settokenbalance(0)
    settokensymbol("BSC")
    setdepositamount(0)
    setnetworklogo(bscimage)
    setnetworkrpc(config.bsctestnetrpcurl)
    setnetworkchainid("56")
    const bridgeOptions = [
      { value: 1, label: "BSC" },
      { value: 2, label: "USDT (BEP20)" }
    ]
    setbridgesoption(bridgeOptions)
    var web3js = new Web3(window.ethereum);
    if (chainname == "metamask" || chainname == "coinbase") {
      const chainId = 0x38;
      const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
      const curchain = web3js.utils.toHex(chainId)
      if (currentchainId != curchain) {
        const chainIdnumber = '56';
        const rpcURL = config.bsctestnetrpcurl;
        const networkName = 'BSC Smart Chain';
        const currencyName = 'BNB';
        const currencySymbol = 'BNB';
        const explorerURL = 'https://bscscan.com/';
        const chainId = await web3js.utils.toHex(chainIdnumber);
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3js.utils.toHex(chainId) }]
          });
        } catch (error) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chainId,
                chainName: networkName,
                rpcUrls: [rpcURL],
                blockExplorerUrls: [explorerURL],

                nativeCurrency: {
                  name: currencyName,
                  symbol: currencySymbol,
                  decimals: 18,
                },
              },
            ],
          });
        }
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        var web3js = new Web3(config.bsctestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(3);
        settokenbalance(tokenbalance)

        setaccountaddress(accounts[0])
        // setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("BSC")
        setbridgenetworksymbol("DCX")
      }
      else {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        
        var web3js = new Web3(config.bsctestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(3);
        settokenbalance(tokenbalance)
        setaccountaddress(accounts[0])
        // setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("bsc")
        setbridgenetworksymbol("DCX")
      }
    }
    else if (chainname == null) {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setaccountaddress(accounts[0])
      sessionStorage.setItem('chainname', "metamask")
      sessionStorage.setItem('chainaccounts', accounts[0])
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex('0x38') }]
      });
      var web3js = new Web3(config.bsctestnetrpcurl);
      const balance = await web3js.eth.getBalance(accounts[0])
      const balanceseth = web3js.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balanceseth).toFixed(3);
      settokenbalance(tokenbalance)
    }
    else {
      console.log("")
    }
  }

  const getmaxvaluecontract = async (event) => {
    const gettrxusdt = await axios.get(config.BINANCE_API)
    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (todropdownvalue != "") {
      if (currentchainId == "0x38" && tokensymbol == "BSC") {
        const trnbscdcoin = ((event * gettrxusdt.data[12].lastPrice) / usdchangeran)
        setdcxamountvalue(trnbscdcoin);
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId == "0x38" && tokensymbol == "USDT") {
        const dcxamount = event / usdchangeran;
        setdcxamountvalue(dcxamount)
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId == "0x89" && tokensymbol == "MATIC") {
        const trnmaticdcoin = ((event * gettrxusdt.data[140].lastPrice) / usdchangeran)
        setdcxamountvalue(trnmaticdcoin)
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId == "0x89" && tokensymbol == "USDT") {
        const dcxamount = event / usdchangeran;
        setdcxamountvalue(dcxamount)
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId === "0x1" && tokensymbol == "ETH") {
        const minimumamt = event * gettrxusdt.data[7].lastPrice
        const trnethdcoin = ((event * gettrxusdt.data[7].lastPrice) / usdchangeran)
        setdcxamountvalue(trnethdcoin)
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId == "0x1" && tokensymbol == "USDT") {
        const dcxamount = event / usdchangeran;
        setdcxamountvalue(dcxamount)
        setShow(false)
        setShowError()
      }
      else {
        console.log("something went wrong")
      }
    }
    else {
      toast.error("Please Select Buy Option");
    }
  }

  const gettovaluecontract = async (event) => {
    event.preventDefault()
    setdcxamountvalue(event.target.value);
    const gettrxusdt = await axios.get(config.BINANCE_API);
    const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
    if (currentchainId == "0x38" && tokensymbol == "BSC") {
      const trnbscdcoin = ((event.target.value * usdchangeran) / gettrxusdt.data[12].lastPrice)
      setdepositamount(trnbscdcoin.toString())
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x38" && tokensymbol == "USDT") {
      const dcxamount = event.target.value * usdchangeran;
      setdepositamount(dcxamount.toString());
      setShow(false)
      setShowError()
    }
    else if (currentchainId == "0x89" && tokensymbol == "MATIC") {
      const trnmaticdcoin = ((event.target.value * usdchangeran) / gettrxusdt.data[140].lastPrice)
      setdepositamount(trnmaticdcoin.toString())
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x89" && tokensymbol == "USDT") {
      const dcxamount = event.target.value * usdchangeran;
      setdepositamount(dcxamount.toString());
      setShow(false)
      setShowError()
    }
    else if (currentchainId === "0x1" && tokensymbol == "ETH") {
      const trnethdcoin = ((event.target.value * usdchangeran) / gettrxusdt.data[7].lastPrice)
      setdepositamount(trnethdcoin.toString());
      setShow(false)
      setShowError(false)
    }
    else if (currentchainId == "0x1" && tokensymbol == "USDT") {
      const dcxamount = event.target.value * usdchangeran;
      setdepositamount(dcxamount.toString());
      setShow(false)
      setShowError()
    }
    else {
      console.log("Something went wrong")
    }
  }

  const changefromvalues = async (values, usdrange) => {
    if (usdrange !== undefined) {
      const gettrxusdt = await axios.get(config.BINANCE_API)
      const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
      if (currentchainId == "0x38" && tokensymbol == "BSC") {
        const trnbscdcoin = ((values * gettrxusdt.data[12].lastPrice) / usdrange)
        setdcxamountvalue(trnbscdcoin);
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId == "0x38" && tokensymbol == "USDT") {
        const dcxamount = values / usdrange;
        setdcxamountvalue(dcxamount)
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId == "0x89" && tokensymbol == "MATIC") {
        const trnmaticdcoin = ((values * gettrxusdt.data[140].lastPrice) / usdrange)
        setdcxamountvalue(trnmaticdcoin)
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId == "0x89" && tokensymbol == "USDT") {
        const dcxamount = values / usdrange;
        setdcxamountvalue(dcxamount)
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId === "0x1" && tokensymbol == "ETH") {
        const trnethdcoin = ((values * gettrxusdt.data[7].lastPrice) / usdrange)
        setdcxamountvalue(trnethdcoin)
        setShow(false)
        setShowError(false)
      }
      else if (currentchainId == "0x1" && tokensymbol == "USDT") {
        const dcxamount = values / usdrange;
        setdcxamountvalue(dcxamount)
        setShow(false)
        setShowError()
      }
      else {
        console.log("something went wrong")
      }
    }
  }


  const getfromvaluecontract = async (event) => {
    event.preventDefault()
    setdepositamount(event.target.value)
    changefromvalues(event.target.value, usdchangeran);
  }


  const transfer = async (e) => {
    if (dcxamountvalue === 0) {
      toast.error("Please enter value in amount...");
    } else {

    let refaddr = ReferralUserAddress;
    const accounts = sessionStorage.getItem("chainaccounts");

    //** mainnet trasfer amount */ 
    if(tocontractaddress == "DUSD") { 
      if (window.ethereum.chainId == "0x38") {
        const setdatas = {
          useraccount: accounts,
          deposits: depositamount,
          tokenselect: selecttoken,
          ipaddr: ip,
          id: id,
          refaddr: refaddr,
          toamountchange: dcxamountvalue
        };
        await transferbsctoken(setdatas);
      }
      else if (window.ethereum.chainId == "0x89") {
        const setdatas = {
          useraccount: accounts,
          deposits: depositamount,
          tokenselect: selecttoken,
          ipaddr: ip,
          id: id,
          refaddr: refaddr,
          toamountchange: dcxamountvalue
        };
        await transfermatictoken(setdatas);
      }
      else if (window.ethereum.chainId == "0x1") {
        const setdatas = {
          useraccount: accounts,
          deposits: depositamount,
          tokenselect: selecttoken,
          ipaddr: ip,
          id: id,
          refaddr: refaddr,
          toamountchange: dcxamountvalue
        };
        await transferethtoken(setdatas);
      } else {
        console.log("wrong network");
      }
    }
    else {
      if (window.ethereum.chainId == "0x38") {
        const setdatas = {
          useraccount: accounts,
          deposits: depositamount,
          tokenselect: selecttoken,
          ipaddr: ip,
          id: id,
          refaddr: refaddr,
          toamountchange: dcxamountvalue
        };
        await transferbscdcxtoken(setdatas);
      }
      else if (window.ethereum.chainId == "0x89") {
        const setdatas = {
          useraccount: accounts,
          deposits: depositamount,
          tokenselect: selecttoken,
          ipaddr: ip,
          id: id,
          refaddr: refaddr,
          toamountchange: dcxamountvalue
        };
        await transfermaticdcxtoken(setdatas);
      }
      else if (window.ethereum.chainId == "0x1") {
        const setdatas = {
          useraccount: accounts,
          deposits: depositamount,
          tokenselect: selecttoken,
          ipaddr: ip,
          id: id,
          refaddr: refaddr,
          toamountchange: dcxamountvalue
        };
        await transferethdcxtoken(setdatas);
      } else {
        console.log("wrong network");
      }
    }
  }
  }

  // Polygon
  const maticnetworkbridge = async () => {
    setdcxamountvalue(0)
    setdropdownvalue("")
    settokenbalance(0)
    setdepositamount(0)
    setnetworklogo(maticimg)
    settokensymbol("MATIC")
    const maticbridgeOptions = [
      { value: 1, label: "MATIC" },
      { value: 2, label: "USDT" }
    ]
    setbridgesoption(maticbridgeOptions)
    var web3js = new Web3(window.ethereum);
    if (chainname == "metamask" || chainname == "coinbase") {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const chainId = 0x89;
      const currentchainId = window.ethereum.chainId;
      const curchain = web3js.utils.toHex(chainId)
      if (currentchainId != curchain) {
        const chainIdnumber = '137';
        const rpcURL = config.matictestnetrpcurl;
        const networkName = 'MATIC Smart Chain';
        const currencyName = 'MATIC';
        const currencySymbol = 'MATIC';
        const explorerURL = 'https://polygonscan.com/';
        const chainId = await web3js.utils.toHex(chainIdnumber);
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3js.utils.toHex(chainId) }]
          });
        } catch (error) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chainId,
                chainName: networkName,
                rpcUrls: [rpcURL],
                blockExplorerUrls: [explorerURL],

                nativeCurrency: {
                  name: currencyName,
                  symbol: currencySymbol,
                  decimals: 18,
                },
              },
            ],
          });
        }
        var web3js = new Web3(config.matictestnetrpcurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balancesmatic = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balancesmatic).toFixed(3);
        settokenbalance(tokenbalance)

        // setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setaccountaddress(accounts[0])
        setbridgenetworksymbol("DCX")
      }
      else {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        sessionStorage.setItem('chainname', "metamask")
        sessionStorage.setItem('chainaccounts', accounts[0])

        setnetworklogo(maticimg)
        // setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("MATIC")
        settokensymbol("USDT")
        setaccountaddress(accounts[0])
        setbridgenetworksymbol("DCX")
      }
    }
    else if (chainname == null) {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setaccountaddress(accounts[0])
      var web3js = new Web3(config.matictestnetrpcurl);
      const balance = await web3js.eth.getBalance(accounts[0])
      const balancesmatic = web3js.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balancesmatic).toFixed(3);
      settokenbalance(tokenbalance)
      sessionStorage.setItem('chainname', "metamask")
      sessionStorage.setItem('chainaccounts', accounts[0])
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex('0x89') }]
      });
      sessionStorage.setItem('chainname', "metamask")
      sessionStorage.setItem('chainaccounts', accounts[0])
    }
  }

  //ETHnetwork
  const ethnetworkbridge = async () => {
    setdcxamountvalue(0)
    setdropdownvalue("")
    settokenbalance(0)
    setnetworklogo(ethimg)
    settokensymbol("ETH")
    setdepositamount(0)
    const maticbridgeOptions = [
      { value: 1, label: "ETH" },
      { value: 2, label: "USDT (ERC20)" }
    ]
    setbridgesoption(maticbridgeOptions)
    if (chainname == "metamask" || chainname == "coinbase") {
      const chainId = 0x1;
      const currentchainId = window.ethereum.chainId;
      var web3js = new Web3(window.ethereum);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      if (currentchainId != chainId) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3js.utils.toHex(chainId) }]
        });

        var web3dchainjs = new Web3(config.dchainrpcurl);
       
        var web3jsethbal = new Web3(config.web3dchainurl);
        const balance = await web3jsethbal.eth.getBalance(accounts[0])
        const balanceseth = web3jsethbal.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(4);
        settokenbalance(tokenbalance)

        // setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("ETH")
        setaccountaddress(accounts[0])
        setbridgenetworksymbol("DCX")
      }
      else {
        var web3dchainjs = new Web3(config.dchainrpcurl);
       
        var web3js = new Web3(config.web3dchainurl);
        const balance = await web3js.eth.getBalance(accounts[0])
        const balanceseth = web3js.utils.fromWei(balance, 'ether');
        const tokenbalance = parseFloat(balanceseth).toFixed(4);
        settokenbalance(tokenbalance)

        setnetworklogo(ethimg)
        // setbscbal(balancesdchainfixed)
        setbridgenetworklogo(dchainimg)
        setnetwork("ETH")
        setaccountaddress(accounts[0])
        setbridgenetworksymbol("DCX")
      }
    }
    else {
      const chainId = 0x1;
      const currentchainId = window.ethereum.chainId;
      var web3js = new Web3(window.ethereum);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      sessionStorage.setItem('chainname', "metamask")
      sessionStorage.setItem('chainaccounts', accounts[0])
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3js.utils.toHex(chainId) }]
      });
      var web3dchainjs = new Web3(config.dchainrpcurl);

      var web3ethbaljs = new Web3(config.web3dchainurl);
      const balance = await web3ethbaljs.eth.getBalance(accounts[0])
      const balanceseth = web3ethbaljs.utils.fromWei(balance, 'ether');
      const tokenbalance = parseFloat(balanceseth).toFixed(4);
      settokenbalance(tokenbalance)
      setnetworklogo(ethimg)
      // setbscbal(balancesdchainfixed)
      setbridgenetworklogo(dchainimg)
      setnetwork("ETH")
      setaccountaddress(accounts[0])
      setbridgenetworksymbol("DCX")
    }
  }

  //max value
  const max = async () => {
    const depositpercent = ((tokenbalance * 99) / 100).toString();
    setdepositamount(depositpercent)
    getmaxvaluecontract(depositpercent)
  }

  //networkcheck
  const networkcheck = async (e) => {
    if (e === "MATIC") {
      maticnetworkbridge();
      setnetworkrpc(config.matictestnetrpcurl)
      setnetworkchainid("137")
      $('.switch-tabs-section-poly').css('background', '#1b9ffe');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', 'transparent');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-eth').css('background', 'transparent');
      $('.switch-tabs-section-eth').css('color', '#FFF');
    }
    else if (e === "ETH") {
      ethnetworkbridge();
      setnetworkrpc(config.web3dchainurl)
      setnetworkchainid("1")
      $('.switch-tabs-section-eth').css('background', '#1b9ffe');
      $('.switch-tabs-section-eth').css('color', '#FFF');
      $('.switch-tabs-section-bsc').css('background', 'transparent');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
    }
    else if (e === "BSC") {
      metamask();
      $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
      $('.switch-tabs-section-bsc').css('color', '#FFF');
      $('.switch-tabs-section-poly').css('background', 'transparent');
      $('.switch-tabs-section-poly').css('color', '#FFF');
      $('.switch-tabs-section-eth').css('background', 'transparent');
      $('.switch-tabs-section-eth').css('color', '#FFF');
    }
  }

  const getRefAddr = async () => {
    let transferobjects = {
      id: id
    }
    try {
      const getaddr = await axios.post(`${config.APP_API_URL}getrefaddr`, transferobjects);
      setReferralUserAddress(getaddr.data.data.userAddress)
    } catch (error) {
      navigate('/')
    }

  }


  // const getAdminAddressValue = async()=>{
  //   const dchaincontract = new web3dchainjs.eth.Contract(erc20Abi, config.DUSD_TOKEN_ADDRESS);
  //   const dchainbalance = await dchaincontract.methods.balanceOf(userAddress).call();
  //   const balancesdchain = web3dchainjs.utils.fromWei(dchainbalance, 'ether');
  //   const balancesdchainfixed = Number(balancesdchain).toFixed(8)
  //   setbscbal(balancesdchainfixed)
  // }

  useEffect(() => {




    const dcxprice =async()=>{
      const res = await axios.get(`${config.APP_API_URL}api/coinmarketcap`);
      const data = res.data.data.DCX.quote.USD.price;
      const usd = data.toFixed(4);
    if (usd > 0) {
      setusdchangeran(usd)
    }else{
      setusdchangeran(0)
    }
    }
    if (window.ethereum) {
      getIP();
      bridges();
      getRefAddr();
      const reloadCount = sessionStorage.getItem('chainaccount');
      setaccountaddress(reloadCount)
    }
    else {
      toast.warn("Check Metamask is Installed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    dcxprice();
  }, []);

  const handleKeyPress = async (event) => {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }

  return (
    <div className="App">
      <Navigate useraddress={accountaddress} networkname={networkrpc ? networkrpc : config.bsctestnetrpcurl} networkchain={networkchainid ? networkchainid : "56"} />

      <section className="py-lg-5 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 switch-tabs-section">
              <div className="btn-group switch-tabs-btn-group">
                <button type="button" onClick={() => networkcheck("ETH")} className="btn switch-tabs-section-eth">
                  <img className="network-image" src={ethimg} alt="ETH Network" />Purchase by using ETH Network
                </button>
                <button type="button" onClick={() => networkcheck("BSC")} className="btn switch-tabs-section-bsc">
                  <img className="network-image" src={bscimage} alt="BSC Network" />Purchase by using BSC Network
                </button>
                <button type="button" onClick={() => networkcheck("MATIC")} className="btn switch-tabs-section-poly">
                  <img className="network-image" src={maticimg} alt="POLYGON Network" />Purchase by using POLYGON Network
                </button>
              </div>
            </div>
            <div className="col-lg-8 switch-banner-section pb-lg-5 mt-4">
              <p className="switch-banner-section-text-1 text-center" >
                Buy 1 {bridgenetworksymbol} at {usdchangeran} USD
              </p>
              <div className="switch-banner-section-inner mb-3">
                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-12 text-start">
                      <select className="form-select" onChange={rewardset} value={dropdownvalue}>
                        <option value="" disabled selected hidden>Select option...</option>
                        {bridgesoption.map(item => (
                          <option key={item.value} value={item.label} className="option-value">
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="switch-banner-section-inner">
                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You Spend
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {tokenbalance}
                        </span>
                      </span>
                      <br className="mobile-break" />
                      {/* <span data-bs-toggle="modal" data-bs-target="#infoModal">
                        <AiOutlineInfoCircle className="switch-banner-section-text-4" />
                      </span> */}
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-6 text-start">
                      <input type="number" className="form-control form-control-amount" min="0" placeholder="Enter the amount" id="from_amount" onChange={(e) => getfromvaluecontract(e)} value={depositamount} onKeyPress={handleKeyPress} />
                    </div>
                    <div className="col amount-select-section">
                      <div className="row">
                        <div className="col-lg-4 col-4 text-lg-end">
                          <span>
                            <button type="button" className="btn-max" onClick={max}>
                              MAX
                            </button>
                          </span>
                        </div>
                        <div className="col text-end text-lg-center">
                          <span className="amount-select-from">
                            <img src={networklogo} className="amount-select-image" alt="networklogo" /><span className="amount-select-name">{tokensymbol}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="switch-banner-centericon-section">
                  <div className="row py-2">
                    <div className="col">
                      <HiArrowDown className="centericon-swap" />
                    </div>
                  </div>
                </div>

                <div className="switch-banner-section-inner mb-3">
                  <div className="switch-banner-amount-section">
                    <div className="row">
                      <div className="col-lg-12 text-start">
                        <select className="form-select" onChange={torewardset} value={todropdownvalue}>
                          <option value="" disabled selected hidden>Select option...</option>
                          {tobridgesoption.map(item => (
                            <option key={item.value} value={item.label} className="option-value">
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="switch-banner-amount-section">
                  <div className="row">
                    <div className="col-lg-6 col-4 text-start">
                      <span className="switch-banner-section-text-2">
                        Amount You Buy
                      </span>
                    </div>
                    <div className="col text-end">
                      <span className="switch-banner-section-text-2">
                        Balance : <span className="switch-banner-section-text-3">
                          {bscbal}
                        </span>
                      </span>
                      <br className="mobile-break" />
                      {/* <span data-bs-toggle="modal" data-bs-target="#infoModal2">
                        <AiOutlineInfoCircle className="switch-banner-section-text-4" />
                      </span> */}
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-12 text-start">
                      <span className="form-control form-control-amount2" min="0" placeholder="0.00" id="to_amount">
                        <input type="number" className="form-control form-control-amount" placeholder="Enter the amount" id="to_amount" onChange={(e) => gettovaluecontract(e)}
                          value={dcxamountvalue} onKeyPress={handleKeyPress} />
                      </span>
                    </div>
                    <div className="col amount-select-section mt-2 mt-lg-0">
                      <div className="row flex-lg-row-reVRS">
                        <div className="col text-end text-lg-end">
                          <span className="amount-select-to">
                            <img src={bridgenetworklogo} className="amount-select-image" alt="networklogo" /><span className="amount-select-name">{bridgenetworksymbol}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button-container-1 my-lg-4 my-4">
                  <span className="mas">Buy</span>
                  <button id="work" type="submit" name="Hover" disabled={show} onClick={() => transfer(network)}>
                    BUY
                  </button>
                </div>
                <span style={{ "color": "red" }}>{showError}</span>
              </div>

            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default Referral;
import React from "react";
import $ from "jquery";
import './Style.css';
import Web3 from 'web3'
import bscimage from './images/bscimages.svg.png'
import maticimg from './images/polygon.svg'
import ethimg from './images/Eth.png'
import axios from 'axios';
import InputDataDecoder from 'ethereum-input-data-decoder'
import { homebridgeAbi } from './Abi/homebridgeabi'
import Navigate from "./components/navigate";

import { BiArrowBack, BiLeftArrowAlt } from 'react-icons/bi';
import DataTable, { createTheme } from 'react-data-table-component';

createTheme('solarized', {
    text: {
        primary: '#FFF',
        secondary: '#FFF',
    },
    background: {
        default: 'rgba(31, 125, 192, 0.1)',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#00e5ff21',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            setgettxdetails: [],
            setmatictxdetails: [],
            setethtxdetails: []
        }
    }

    columns = [
        {
            name: "Date",
            selector: (row) => row.sno,
            sortable: true,
        },
        {
            name: "Tnx Hash",
            selector: (row) => <a href={row.link} target={"_blank"}>{row.hash}</a>,
            sortable: true,
        },
        {
            name: "From",
            selector: (row) => row.from,
            sortable: true,
            width: "200px"
        },
        {
            name: "To",
            selector: (row) => row.to,
            sortable: true,
            width: "200px"
        },
        {
            name: "Amount",
            selector: (row) => row.value,
            sortable: true,
        },

    ];

    handleSort = (column, sortDirection) => console.log("=====",column.selector, sortDirection);

    componentDidMount() {
        // $(".table-bsc").hide();
        // $(".table-poly").hide();
        // $(".table-eth").show();
        this.getchainid()
    }

    getchainid = async () => {
        const chainid = await window.ethereum.request({ method: "eth_chainId" });
        if (chainid === '0x38') {
            $(".table-bsc").show();
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
            this.bsctxdetails()
        }
        if (chainid === '0x1') {
            $(".table-bsc").show();
            $('.switch-tabs-section-eth').css('background', '#1b9ffe');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
            this.ethtxdetails()
        }
        if (chainid === '0x89') {
            $(".table-bsc").show();
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', '#1b9ffe');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
            this.matictxdetails()
        }
    }


    bsctxdetails = async () => {
        $(".table-bsc").show();
        $('.switch-tabs-section-eth').css('background', 'transparent');
        $('.switch-tabs-section-eth').css('color', '#FFF');
        $('.switch-tabs-section-poly').css('background', 'transparent');
        $('.switch-tabs-section-poly').css('color', '#FFF');
        $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
        $('.switch-tabs-section-bsc').css('color', '#FFF');
        while(this.state.setgettxdetails.length > 0 ){
            this.state.setgettxdetails.pop();
        }
        const decoder = new InputDataDecoder(homebridgeAbi);
        var web3js = new Web3(window.ethereum);
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const gettxdetails = ['']
         const responres = await axios.get("https://api-testnet.bscscan.com/api?module=account&action=txlist&contractaddress=0x7e94EF4C6207Fb41264A9c1d2434fc93388F8Ff3&address=" + account[0] + "&page=1&offset=100&sort=desc&apikey=NCN23G4SGIEYWR3XWGR3XXWKMXT61BPE4I");  
         for (var i = 0; i < responres.data.result.length; i++) {
            var car = {};
            if (responres.data.result[i].to == "0x7e94ef4c6207fb41264a9c1d2434fc93388f8ff3") {
                var dateend = new Date(responres.data.result[i].timeStamp * 1000);
                const resultinput = await decoder.decodeData(responres.data.result[i].input);
                if (resultinput.inputs[0]._hex == '0x00') {
                    var car = {
                        "sno": dateend.toLocaleString(),
                        "link": "https://testnet.bscscan.com/tx/" + responres.data.result[i].hash,
                        "hash": responres.data.result[i].hash,
                        "from": responres.data.result[i].from,
                        "to": responres.data.result[i].to,
                        "value": await web3js.utils.fromWei(responres.data.result[i].value, 'ether') + " BSC"
                    }
                    gettxdetails.push(car);
            
                    
                } else {
                    const hexToDecimal2 = (parseInt(resultinput.inputs[0]._hex, 16)) / 1e18;
                    var car = {
                        "sno": dateend.toLocaleString(),
                        "link": "https://testnet.bscscan.com/tx/" + responres.data.result[i].hash,
                        "hash": responres.data.result[i].hash,
                        "from": responres.data.result[i].from,
                        "to": responres.data.result[i].to,
                        "value": hexToDecimal2 + " USDT"
                    }
                    gettxdetails.push(car);
                   
                }
                const setpaths = gettxdetails.filter(Boolean);
                this.setState({
                    setgettxdetails: setpaths
                })
            }
        }
    
    }

    matictxdetails = async () => {

        $(".table-bsc").show();

        $('.switch-tabs-section-eth').css('background', 'transparent');
        $('.switch-tabs-section-eth').css('color', '#FFF');
        $('.switch-tabs-section-poly').css('background', '#1b9ffe');
        $('.switch-tabs-section-poly').css('color', '#FFF');
        $('.switch-tabs-section-bsc').css('background', 'transparent');
        $('.switch-tabs-section-bsc').css('color', '#FFF');
        this.state.setgettxdetails = []

        // while(this.state.setgettxdetails.length > 0 ){
        //     this.state.setgettxdetails.pop();
        // }
        const decoder = new InputDataDecoder(homebridgeAbi);
        var web3js = new Web3(window.ethereum);
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const gettxdetails = ['']
        const response = await axios.get("https://api-testnet.polygonscan.com/api?module=account&action=txlist&contractaddress=0xf9A9838Bc1044B3d8f8784c79FaB1a0846330886&address=" + account[0] + "&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=7VJGEDNEVBT9BAFVN2GHYSCZ1ZE1V5SYPS");      
        for (var i = 0; i < response.data.result.length; i++) {
            var bus = {};
            const webchecksumaddr = await web3js.utils.toChecksumAddress(response.data.result[i].to);
          
            if (webchecksumaddr === "0xf9A9838Bc1044B3d8f8784c79FaB1a0846330886") {
                var dateend = new Date(response.data.result[i].timeStamp * 1000);
                var amountsend = await web3js.utils.fromWei(response.data.result[i].value, 'ether')
                const resultinput = decoder.decodeData(response.data.result[i].input);
                if (resultinput.inputs[0]._hex === '0x00') {
                    bus["sno"] = dateend.toLocaleString()
                    bus["link"] = "https://mumbai.polygonscan.com/tx/" + response.data.result[i].hash
                    bus["hash"] = response.data.result[i].hash
                    bus["from"] = response.data.result[i].from
                    bus["to"] = response.data.result[i].to
                    bus["value"] = amountsend + " Matic"
                    gettxdetails.push(bus);
                }
                else {
                    const hexToDecimal2 = (parseInt(resultinput.inputs[0]._hex, 16)) / 1e18;
                    bus["sno"] = dateend.toLocaleString()
                    bus["link"] = "https://mumbai.polygonscan.com/tx/" + response.data.result[i].hash
                    bus["hash"] = response.data.result[i].hash
                    bus["from"] = response.data.result[i].from
                    bus["to"] = response.data.result[i].to
                    bus["value"] = hexToDecimal2 + " USDT"
                    gettxdetails.push(bus);
                   
                }
                const setpaths = gettxdetails.filter(Boolean);
                this.setState({
                    setgettxdetails: setpaths
                })
            
        }
    }
    }

    ethtxdetails = async () => {
        $(".table-bsc").show();
        $('.switch-tabs-section-eth').css('background', '#1b9ffe');
        $('.switch-tabs-section-eth').css('color', '#FFF');
        $('.switch-tabs-section-poly').css('background', 'transparent');
        $('.switch-tabs-section-poly').css('color', '#FFF');
        $('.switch-tabs-section-bsc').css('background', 'transparent');
        $('.switch-tabs-section-bsc').css('color', '#FFF');
        // $(function () {
        //     $('.switch-tabs-section-eth').click(function () {

        //     });
        // });
        const decoder = new InputDataDecoder(homebridgeAbi);
        var web3js = new Web3(window.ethereum);
        const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const gettxdetails = ['']
        const response = await axios.get("https://api-sepolia.etherscan.io/api?module=account&action=txlist&contractaddress=0x669e836f5f89ceC212aaCF992e8E4624Da4A3758&address=" + account[0] + "&page=1&offset=100&sort=desc&apikey=6GFI8X5AI2DP5VFITIBRRWDASRCCHYK18M")
       
        for (var i = 0; i < response.data.result.length; i++) {
            var bus = {};
            const webchecksumaddr = await web3js.utils.toChecksumAddress(response.data.result[i].to);
            if (webchecksumaddr === "0x669e836f5f89ceC212aaCF992e8E4624Da4A3758") {
                var dateend = new Date(response.data.result[i].timeStamp * 1000);
                var amountsend = await web3js.utils.fromWei(response.data.result[i].value, 'ether')
                const resultinput = decoder.decodeData(response.data.result[i].input);
                if (resultinput.inputs[0]._hex === '0x00') {
                    bus["sno"] = dateend.toLocaleString()
                    bus["link"] = "https://sepolia.etherscan.io/tx/" + response.data.result[i].hash
                    bus["hash"] = response.data.result[i].hash
                    bus["from"] = response.data.result[i].from
                    bus["to"] = response.data.result[i].to
                    bus["value"] = amountsend + " ETH"
                    gettxdetails.push(bus);
                   
                }
                else {
                    const hexToDecimal2 = (parseInt(resultinput.inputs[0]._hex, 16)) / 1e18;
                    bus["sno"] = dateend.toLocaleString()
                    bus["link"] = "https://sepolia.etherscan.io/tx/" + response.data.result[i].hash
                    bus["hash"] = response.data.result[i].hash
                    bus["from"] = response.data.result[i].from
                    bus["to"] = response.data.result[i].to
                    bus["value"] = hexToDecimal2 + " USDT"
                    gettxdetails.push(bus);
                    
                }
                const setpaths = gettxdetails.filter(Boolean);
                this.setState({
                    setgettxdetails: setpaths
                })
            
        }
    }
    }





    render() {
        return (
            <div className="App">
                <section className='py-5'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-12 switch-tabs-section'>
                                <div className="text-start mb-lg-0 mb-3">
                                    <a href="/"><span className="trans-back-button"><BiLeftArrowAlt /></span></a>
                                </div>
                                <div className="btn-group switch-tabs-btn-group">
                                    <a href="#eth"><button type="button" onClick={this.ethtxdetails} className="btn switch-tabs-section-eth"><img className="network-image" src={ethimg} alt="eth Network" />eth Network</button></a>
                                    <a href="#bsc"><button type="button" onClick={this.bsctxdetails} className="btn switch-tabs-section-bsc"><img className="network-image" src={bscimage} alt="BSC Network" />BSC Network</button></a>
                                    <a href="#matic"><button type="button" onClick={this.matictxdetails} className="btn switch-tabs-section-poly"><img className="network-image" src={maticimg} alt="POLYGON Network" />POLYGON Network</button></a>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <div className="table-bsc gSVZoG">
                                    <DataTable
                                        columns={this.columns}
                                        data={this.state.setgettxdetails}
                                        defaultSortFieldId
                                        pagination={5}
                                        highlightOnHover
                                        theme="solarized"
                                        onSort={this.handleSort}
                                    />
                                </div>
                                {/* <div className=" table-poly gSVZoG">
                                    <DataTable
                                        columns={this.columns}
                                        data={this.state.setmatictxdetails}
                                        defaultSortFieldId
                                        pagination={5}
                                        // onRowClicked={handleRowClicked}
                                        highlightOnHover
                                        theme="solarized"
                                        onSort={this.handleSort}
                                    />
                                </div>

                                <div className=" table-eth gSVZoG">
                                    <DataTable
                                        columns={this.columns}
                                        data={this.state.setethtxdetails}
                                        defaultSortFieldId
                                        pagination={5}
                                        // onRowClicked={handleRowClicked}
                                        highlightOnHover
                                        customStyles={this.customStyles}
                                        theme="solarized"
                                        onSort={this.handleSort}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default App;

import React from "react";
// import dlaunchpad from "./images/dlaunchpad.png";
import dlaunchpad from "../images/dlaunchpad.png";
import { VscArrowSwap } from 'react-icons/vsc';
import { MdLeaderboard } from 'react-icons/md';
import WalletConnector from '../connector/wallet-connector';

const Navigate = (props) => {

return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light navbar-section">
    <div className="container">
      <a className="navbar-brand" href="/"><img src={dlaunchpad} alt='DIDO Bridge-logo' /></a>
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mobile-transaction">
      <li className="nav-item">
          <a href="/leaderboard" className="nav-link connect-trans-button me-3 nav-link-ltr"><MdLeaderboard className='me-2 nav-icons' /><span className='tabs-none'>  Refer And Earn</span></a>
        </li>
        <li className="nav-item">
          <a href="transactions#eth" className="nav-link connect-trans-button me-3 nav-link-ltr"><VscArrowSwap className='me-2 nav-icons' /><span className='tabs-none'> TRANSACTIONS</span></a>
        </li>
      </ul>
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li className="nav-item">
          <a href="/leaderboard" className="nav-link connect-trans-button me-3 nav-link-ltr"><MdLeaderboard className='me-2 nav-icons' /><span className='tabs-none'> Refer And Earn</span></a>
        </li>
        <li className="nav-item">
          <a href="transactions#eth" className="nav-link connect-trans-button desktop-transaction me-3 nav-link-ltr"><VscArrowSwap className='me-2 nav-icons' /><span className='tabs-none'> TRANSACTIONS</span></a>
          <WalletConnector props={props}/>
        </li>
      </ul>
    </div>
  </nav>
  </>
)
};
export default Navigate;
let config = {};
let environment = "live";
if (environment === "local") {
  config = {
    BINANCE_API: "https://api.binance.us/api/v1/ticker/24hr" ,
    API_URL: "http://3.82.104.145:4000/web3matic",
    API_URL_DCX: "http://3.82.104.145:4000/web3maticdcx",
    // API_URL:"http://localhost:4000/web3matic",
    // API_URL_DCX: "http://localhost:4000/web3maticdcx",
    REACT_APP_URL : "http://184.73.1.166/",
    APP_API_URL : 'http://3.82.104.145:4000/',
    // APP_API_URL : "http://localhost:4000/",

    ETH_BRIDGE_ADDRESS:"0x669e836f5f89ceC212aaCF992e8E4624Da4A3758",
    BSC_BRIDGE_ADDRESS:"0x7e94EF4C6207Fb41264A9c1d2434fc93388F8Ff3",
    MATIC_BRIDGE_ADDRESS:"0xf9A9838Bc1044B3d8f8784c79FaB1a0846330886",

    web3dchainurl:"https://sepolia.infura.io/v3/be8a6c05d29e453fa05d354047f4e2f2",
    bsctestnetrpcurl:"https://data-seed-prebsc-1-s2.binance.org:8545",
    matictestnetrpcurl : "https://rpc-mumbai.maticvigil.com",
    dchainrpcurl : "https://mainnetcoin.d-ecosystem.io/",

    ETH_TOKEN_ADDRESS:"0x24fDE9cCc95cC43801AE7A71457AC9F7aE1809a9",
    BSC_TOKEN_ADDRESS:"0xe6Fc9E04AE701cf5BA109Ff7168A1510f5D3f768",
    MATIC_TOKEN_ADDRESS:"0x18f79a903a97606b0629dc974b772307dfb89617",
    SEPOLIA_BATTOKEN_ADDRESS:"0xB1F366b980Ed61581F07EA8e79c7c1ad2B1680b5",
    DUSD_TOKEN_ADDRESS : "0x057f6b46c9618c05b1c86244b5950cb699d30aef"
  };
} else {
  config = {
    BINANCE_API: "https://api.binance.us/api/v1/ticker/24hr" ,
    // API_URL: "https://backenddcx.d-ecosystem.io/web3matic",
    // API_URL_DCX: "https://backenddcx.d-ecosystem.io/web3maticdcx",
    // REACT_APP_URL : "https://sales.d-ecosystem.io/",
    // APP_API_URL : 'https://backenddcx.d-ecosystem.io/',
    API_URL:"http://3.82.104.145:4000/web3matic",
    API_URL_DCX :"http://3.82.104.145:4000/web3maticdcx",
    REACT_APP_URL:"http://184.73.1.166/",
    APP_API_URL:"http://3.82.104.145:4000/",

    ETH_BRIDGE_ADDRESS:"0xb0559Fd843f9d5b6706A7cE8B7f94457434bDBe2",
    BSC_BRIDGE_ADDRESS:"0xb0559Fd843f9d5b6706A7cE8B7f94457434bDBe2",
    MATIC_BRIDGE_ADDRESS:"0xA3e0c185a03fEAbbc10f12bDC57AAa7Fd9a1e251",

    web3dchainurl:"https://mainnet.infura.io/v3/20f0e439013b492c9a149b08a32c33ad",
    bsctestnetrpcurl:"https://bsc-dataseed1.binance.org/",
    matictestnetrpcurl : "https://polygon-rpc.com/",
    dchainrpcurl : "https://mainnetcoin.d-ecosystem.io/",

    ETH_TOKEN_ADDRESS:"0xdAC17F958D2ee523a2206206994597C13D831ec7",
    BSC_TOKEN_ADDRESS:"0x55d398326f99059fF775485246999027B3197955",
    MATIC_TOKEN_ADDRESS:"0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    DUSD_TOKEN_ADDRESS : "0x330555a02Ab09344b86D1c9282af1b1bCB51cafe"
  };
}

export default config;
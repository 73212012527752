import React, { useState, useEffect } from "react";
import { BsWallet2 } from 'react-icons/bs';
import Web3Modal from "web3modal";

import * as ethers  from "ethers";
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import $ from "jquery";
import config from "../config/config";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


const WalletConnector = (props) => {
    const [connectedAccount, setConnectedAccount] = useState("");
    const [chainnames,setchainnames] = useState("");
    const [buttondisconnect,setbuttondisconnect] = useState("False")
    const navigate = useNavigate();

    const providerOptions = {
        walletconnect: {
            package: WalletConnect,
            options: {
                infuraId : props.props.networkname
            }
        },
        // coinbasewallet: {
        //     package: CoinbaseWalletSDK, 
        //     options: {
        //         appName: "Coinbase", 
        //         infuraId : props.props.networkname,
        //         chainId: props.props.networkchain,
        //     },
        // }
    };

    const web3Modal = new Web3Modal({
        theme: "dark", 
        cacheProvider: false, 
        providerOptions
    });

    const connectWeb3Wallet = async () => {
        const web3Provider = await web3Modal.connect();
        const library = new ethers.providers.Web3Provider(web3Provider);
        const accounts = await library.listAccounts();
        if (accounts) setConnectedAccount(accounts[0]);
        sessionStorage.setItem('chainname', library.connection.url)
        sessionStorage.setItem('chainaccounts', accounts[0])
        $("#hidedisconnect").show();  
        getruseraddr(accounts[0]);
        setConnectedAccount(accounts[0].slice(0,7)+".."+accounts[0].slice(-5))
        
    };

    const disconnectWeb3Modal = async () => {
        setConnectedAccount("");
        setConnectedAccount(null)
        await web3Modal.clearCachedProvider();
        setbuttondisconnect("True");
        sessionStorage.removeItem('chainname')
        sessionStorage.removeItem('chainaccounts')
        $("#hideconnect").show(); 
        $("#hidedisconnect").hide(); 
    };

   

    const getruseraddr = async(addre) => {
        let addr = addre.toLowerCase();
        let transferobjects = {
            userAddress: addr
          }
        const getaddr = await axios.post(`${config.APP_API_URL}getuser`, transferobjects);
        if(getaddr.data.data != null) {
            navigate('/');
        }
    }

    useEffect(() => {
        const chainname = sessionStorage.getItem('chainname')
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if(chainaccounts == null && buttondisconnect == "False") {  
          setConnectedAccount(null);
            $("#hidedisconnect").hide(); 
            $("#hideconnect").show(); 
            setConnectedAccount("CONNECT WALLET");
        }
        else {
            $("#hidedisconnect").show(); 
            setchainnames(chainname)
            setConnectedAccount(chainaccounts.slice(0,7)+".."+chainaccounts.slice(-5))
            getruseraddr(chainaccounts)
        }
    },[]);

    return (
        <>  
            <button className="nav-link connect-wallet-button  mt-2"  id="hideconnect" onClick={connectWeb3Wallet}>
                    <BsWallet2 className='me-2' />
                {connectedAccount == "" || connectedAccount == null  ? "CONNECT WALLET":connectedAccount} 
            </button> 
            <button className="nav-link connect-wallet-button-2 mt-2 ms-2" id="hidedisconnect" onClick={disconnectWeb3Modal}>
                <BsWallet2 className='me-2' />
                {connectedAccount !== ""?"Disconnect":""}
            </button>
        </>
    )
};
export default WalletConnector;
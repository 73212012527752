import Web3 from "web3";
import { erc20Abi } from "../Abi/erc20abi";
import { homebridgeAbi } from "../Abi/homebridgeabi";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config/config";


export const transferbsctoken = async (data = {}) => {
  var web3js = new Web3(window.ethereum);
  const accounts = data.useraccount;
  let accountlower = accounts.toLowerCase();
  const depositamount = data.deposits;
  const selecttoken = data.tokenselect;
  const ip = data.ipaddr;
  let toamountchange = data.toamountchange;
  const btomfromcontract = new web3js.eth.Contract(erc20Abi,config.BSC_TOKEN_ADDRESS);
  const bscbridgecontract = new web3js.eth.Contract(homebridgeAbi,config.BSC_BRIDGE_ADDRESS);

  const transfertoken = web3js.utils.toWei(depositamount, "ether");
  if (selecttoken == "USDT (BEP20)") {
    await btomfromcontract.methods.approve(config.BSC_BRIDGE_ADDRESS, transfertoken).send({ from: accounts });
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(transfertoken)
      .send({
        from: accounts,
      }).on('error', function(error, receipt) { 
        if(error.code == 4001) {
          window.location.reload()
        }
    });
    const txhash = transferfn.transactionHash;
    var networkname = "BSC";
    var transferobjects = {
      accounts,
      networkname,
      txhash,
      toamountchange
    };
  
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      await axios({
        method: "POST",
        url: `${config.APP_API_URL}user_create`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          userAddress: accountlower,
          ip_address: ip,
          first_purchase_amt: transferfn.events.tokenTransfer.returnValues.amount,
          currency_type: "USDT",
          first_purchase_dcx_amt : toamountchange
        },
      })
        .then(async (userData) => {
        })
        .catch((err) => console.log(err));
      
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${
            "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
            <br/><br/>Refer & Earn 5% Commission`,
      }).then(function () {
        window.location = "/leaderboard";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  } else {
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(0).send({
        from: accounts,
        value: web3js.utils.toWei(depositamount, "ether"),
      }).on('error', function(error, receipt) { 
        if(error.code == 4001) {
          window.location.reload()
        }
    });
    const txhash = transferfn.transactionHash;
    var networkname = "BSC";
    var transferobjects = {
      accounts,
      networkname,
      txhash,
      toamountchange
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      await axios({
        method: "POST",
        url: `${config.APP_API_URL}user_create`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          userAddress: accountlower,
          ip_address: ip,
          first_purchase_amt: transferfn.events.coinTransfer.returnValues.amount,
          currency_type: "BSC",
          first_purchase_dcx_amt : toamountchange
        },
      })
        .then(async (userData) => {
         
          console.log("userData====== ");
        })
        .catch((err) => console.log(err));
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${
            "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
            <br/><br/>Refer & Earn 5% Commission`,
      }).then(function () {
        window.location = "/leaderboard";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  }
};

export const transferethtoken = async (data = {}) => {
  var web3js = new Web3(window.ethereum);
  const accounts = data.useraccount;
  let accountlower = accounts.toLowerCase();
    
  const depositamount = data.deposits;
  const selecttoken = data.tokenselect;
  const ip = data.ipaddr;
  let toamountchange = data.toamountchange;

  const bscbridgecontract = new web3js.eth.Contract(homebridgeAbi,config.ETH_BRIDGE_ADDRESS);
  const ethtokencontract = new web3js.eth.Contract(erc20Abi,config.ETH_TOKEN_ADDRESS);
  const decimals = await ethtokencontract.methods.decimals().call();
  const transfertoken = web3js.utils.toWei(depositamount, "Mwei");
  if (selecttoken == "USDT (ERC20)") {
    await ethtokencontract.methods.approve(config.ETH_BRIDGE_ADDRESS, transfertoken).send({ from: accounts });
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(transfertoken).send({
        from: accounts
      }).on('error', function(error, receipt) { 
        if(error.code == 4001) {
          window.location.reload()
        }
    });
    const txhash = transferfn.transactionHash;
    var networkname = "ETH";
    var transferobjects = {
      accounts,
      networkname,
      txhash,
      toamountchange
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      await axios({
        method: "POST",
        url: `${config.APP_API_URL}user_create`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          userAddress: accountlower,
          ip_address: ip,
          first_purchase_amt:
            transferfn.events.tokenTransfer.returnValues.amount,
          currency_type: "USDT",
          first_purchase_dcx_amt : toamountchange
        },
      })
        .then(async (data) => {
          console.log("userData======");
        })
        .catch((err) => console.log(err));
     
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${
            "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
            <br/><br/>Refer & Earn 5% Commission`,
      }).then(function () {
        window.location = "/leaderboard";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  } else {
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    const transferfn = await bscbridgecontract.methods
      .ExchangeETHforToken(0)
      .send({
        from: accounts,
        value: web3js.utils.toWei(depositamount, "ether"),
      }).on('error', function(error, receipt) { 
        if(error.code == 4001) {
          window.location.reload()
        }
      });
    const txhash = transferfn.transactionHash;
    var networkname = "ETH";
    var transferobjects = {
      accounts,
      networkname,
      txhash,
      toamountchange
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      await axios({
        method: "POST",
        url: `${config.APP_API_URL}user_create`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          userAddress: accountlower,
          ip_address: ip,
          first_purchase_amt:
            transferfn.events.coinTransfer.returnValues.amount,
          currency_type: "ETH",
          first_purchase_dcx_amt : toamountchange
        },
      })
        .then(async (userData) => {
          console.log("userData======");
        })
        .catch((err) => console.log(err));
      
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${
            "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
            <br/><br/>Refer & Earn 5% Commission`,
      }).then(function () {
        window.location = "/leaderboard";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  }
};


export const transfermatictoken = async (data = {}) => {
  var web3js = new Web3(window.ethereum);
  const accounts = data.useraccount;
  const depositamount = data.deposits;
  const selecttoken = data.tokenselect;
  let accountlower = accounts.toLowerCase();
  const ip = data.ipaddr;
  let toamountchange = data.toamountchange;
  const bscbridgecontract = new web3js.eth.Contract( homebridgeAbi,config.MATIC_BRIDGE_ADDRESS);
  const matictokencontract = new web3js.eth.Contract(erc20Abi, config.MATIC_TOKEN_ADDRESS);
  const decimals = await matictokencontract.methods.decimals().call();
  const transfertoken = web3js.utils.toWei(depositamount, "Mwei");
  if (selecttoken == "USDT") {
    await matictokencontract.methods.approve(config.MATIC_BRIDGE_ADDRESS, transfertoken).send({ from: accounts });
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(transfertoken)
      .send({
        from: accounts,
      }).on('error', function(error, receipt) { 
        if(error.code == 4001) {
          window.location.reload()
        }
    });
    const txhash = transferfn.transactionHash;
    var networkname = "MATIC";
    var transferobjects = {
      accounts,
      networkname,
      txhash,
      toamountchange
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
      const dcoinget = await axios.post(config.API_URL, transferobjects);
      await axios({
        method: "POST",
        url: `${config.APP_API_URL}user_create`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          userAddress: accountlower,
          ip_address: ip,
          first_purchase_amt:
            transferfn.events.tokenTransfer.returnValues.amount,
          currency_type: "USDT",
          first_purchase_dcx_amt : toamountchange
        },
      })
        .then(async (userData) => {
          console.log("userData======");
        })
        .catch((err) => console.log(err));
      
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${
            "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
        <br/><br/>Refer & Earn 5% Commission`,
      }).then(function () {
        window.location = "/leaderboard";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  } else {
    Swal.fire({
      icon: "warning",
      background: "#041325",
      border: "1px solid #00e5ff21",
      color: "#ffffff",
      title: "Transaction is Processing",
      html: "Do Not Refresh or Reload the Page",
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    const transferfn = await bscbridgecontract.methods.ExchangeETHforToken(0).send({
        from: accounts,
        value: web3js.utils.toWei(depositamount, "ether"),
      }).on('error', function(error, receipt) { 
        if(error.code == 4001) {
          window.location.reload()
        }
    });
    const txhash = transferfn.transactionHash;

    var networkname = "MATIC";
    var transferobjects = {
      accounts,
      networkname,
      txhash,
      toamountchange
    };
    const paymentstatus = transferfn.status;
    if (paymentstatus == true) {
    const dcoinget = await axios.post(config.API_URL, transferobjects);
      const payload = {
        userAddress: accountlower,
        ip_address: ip,
        first_purchase_amt: transferfn.events.coinTransfer.returnValues.amount,
        currency_type: "MATIC",
        first_purchase_dcx_amt : toamountchange
      };
      await axios({
        method: "POST",
        url: `${config.APP_API_URL}user_create`,
        data: payload,
      })
        .then(async (userData) => {
          console.log("userData======", userData);
        })
        .catch((err) => console.log(err));
      Swal.fire({
        icon: "success",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction confirmed",
        html:
          "Your transaction hash is:<br/><br/>" +
          `<a href=${
            "https://mainnet-explorer.d-ecosystem.io/tx/" + dcoinget.data
          }  target="_blank" style="color:#03a2ff;">${dcoinget.data}</a>
        <br/><br/>Refer & Earn 5% Commission`,
      }).then(function () {
        window.location = "/leaderboard";
      });
    } else {
      Swal.fire({
        icon: "error",
        background: "#041325",
        border: "1px solid #00e5ff21",
        color: "#ffffff",
        title: "Transaction Not confirmed",
        html: "Your transaction hash is Not confirmed",
      });
    }
  }
};